var _TopicLink;
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicLink extends Component {
  get url() {
    return this.args.topic.linked_post_number ? this.args.topic.urlForPostNumber(this.args.topic.linked_post_number) : this.args.topic.lastUnreadUrl;
  }
}
_TopicLink = TopicLink;
setComponentTemplate(createTemplateFactory(
/*
  
    <a
      href={{this.url}}
      data-topic-id={{@topic.id}}
      role="heading"
      aria-level="2"
      class="title"
      ...attributes
    >{{htmlSafe @topic.fancyTitle}}</a>
  
*/
{
  "id": "zHnI1DAP",
  "block": "[[[1,\"\\n    \"],[11,3],[16,6,[30,0,[\"url\"]]],[16,\"data-topic-id\",[30,1,[\"id\"]]],[24,\"role\",\"heading\"],[24,\"aria-level\",\"2\"],[24,0,\"title\"],[17,2],[12],[1,[28,[32,0],[[30,1,[\"fancyTitle\"]]],null]],[13],[1,\"\\n  \"]],[\"@topic\",\"&attrs\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/topic-link.js",
  "scope": () => [htmlSafe],
  "isStrictMode": true
}), _TopicLink);