import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const UnreadIndicator = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @includeUnreadIndicator~}}
    &nbsp;<span
      title={{i18n "topic.unread_indicator"}}
      class={{concatClass
        "badge badge-notification unread-indicator"
        (concat "indicator-topic-" @topicId)
      }}
      ...attributes
    >
      {{~icon "asterisk"~}}
    </span>
  {{~/if}}

*/
{
  "id": "uiagkFle",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\" \"],[11,1],[16,\"title\",[28,[32,0],[\"topic.unread_indicator\"],null]],[16,0,[28,[32,1],[\"badge badge-notification unread-indicator\",[28,[32,2],[\"indicator-topic-\",[30,2]],null]],null]],[17,3],[12],[1,[28,[32,3],[\"asterisk\"],null]],[13]],[]],null]],[\"@includeUnreadIndicator\",\"@topicId\",\"&attrs\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/unread-indicator.js",
  "scope": () => [i18n, concatClass, concat, icon],
  "isStrictMode": true
}), templateOnly());
export default UnreadIndicator;